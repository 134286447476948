import React, { createContext, useContext, useState } from 'react';

interface PermissionsContextType {
  memberRemoveVisible: boolean;
  setMemberRemoveVisible: ATVoidFunction<boolean>;
  membersInviteVisible: boolean;
  setMembersInviteVisible: ATVoidFunction<boolean>;
}

const PermissionsContext = createContext<PermissionsContextType | undefined>(
  undefined,
);

export const usePermissionsContext = () => {
  const context = useContext(PermissionsContext);

  if (!context) {
    throw new Error(
      'usePermissionsContext must be used within a PermissionsProvider',
    );
  }

  return context;
};

interface PermissionContextProviderProps {
  children: React.ReactNode;
}

export const PermissionContextProvider: React.FC<
  PermissionContextProviderProps
> = ({ children }) => {
  const [memberRemoveVisible, setMemberRemoveVisible] = useState(false);
  const [membersInviteVisible, setMembersInviteVisible] = useState(false);

  return (
    <PermissionsContext.Provider
      value={{
        memberRemoveVisible,
        setMemberRemoveVisible,
        membersInviteVisible,
        setMembersInviteVisible,
      }}
    >
      {children}
    </PermissionsContext.Provider>
  );
};
