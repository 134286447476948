import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { AtiraEmpty } from '../../components/AtiraEmpty';
import { Flex } from '../../components/Flex';
import { SubHeader } from '../../components/SubHeader';
import { WarningModal } from '../../components/WarningModal';
import { Form } from '../../model/form/Form';
import { formSliceSelectors } from '../../redux/forms/form.selector';
import { formActions } from '../../redux/forms/form.slice';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { thresholdActions } from '../../redux/threshold/threshold.slice';
import { userSliceSelectors } from '../../redux/user/user.selector';
import { Spacing } from '../../theme/Spacing';
import { AtiraToast } from '../../utils/AtiraToast';
import { EntryCreateModal } from '../entries/components/EntryCreateModal';
import { canCreateForm } from './FormUtils';
import { FormReadModal } from './components/FormReadModal';
import { FormShareModal } from './components/ShareFormModal';
import { UserForm } from './components/UserForm';

const Wrapper = styled(Flex)`
  flex-direction: column;
  padding: 0 ${Spacing.m};
  flex-direction: column;
  gap: ${() => `${Spacing.m}`};
  width: 100%;
  height: 100%;
  align-content: space-between;
`;

export const UserForms: React.FC = () => {
  const { t } = useTranslation();

  const [hoveredFormId, setHoveredFormId] = useState<string | null>(null);
  const [loading, setloading] = useState(false);
  const [formShareModalVisible, setFormShareModalVisible] = useState(false);
  const [formReadModalVisible, setFormReadModalVisible] = useState(false);
  const [formDeleteModalVisible, setFormDeleteModalVisible] = useState(false);
  const [entryCreateModalVisible, setEntryCreateModalVisible] = useState(false);
  const [formDuplicateModalVisible, setFormDuplicateModalVisible] =
    useState(false);

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const userForms = useAppSelector(formSliceSelectors.selectMyForms)?.filter(
    (f) => f.orphan !== true,
  );
  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;
  const state = useAppSelector((s) => s);
  const currentForm = useAppSelector(formSliceSelectors.selectCurrentForm);

  const openShareModal: ATVoidFunction<Form> = (form) => {
    dispatch(formActions.setCurrentForm(form));
    setFormShareModalVisible(true);
  };

  const openPreviewModal: ATVoidFunction<Form> = (form) => {
    dispatch(formActions.setCurrentForm(form));
    setFormReadModalVisible(true);
  };

  const navigateToFormEntries: ATVoidFunction<Form> = (form) => {
    dispatch(formActions.setCurrentForm(form));
    navigate('/entries', { state: { useFilter: form } });
  };

  const openCreateNewEntryModal: ATVoidFunction<Form> = (form) => {
    dispatch(formActions.setCurrentForm(form));
    setEntryCreateModalVisible(true);
  };

  const openDeleteFormModal: ATVoidFunction<Form> = (form) => {
    dispatch(formActions.setCurrentForm(form));
    setFormDeleteModalVisible(true);
  };

  const openFormDuplicateModal: ATVoidFunction<Form> = (form) => {
    dispatch(formActions.setCurrentForm(form));
    setFormDuplicateModalVisible(true);
  };

  const onEdit: ATVoidFunction<Form> = (form) => {
    navigate('/forms/edit', { state: form });
  };

  const deleteForm = async () => {
    try {
      if (!currentForm) {
        return AtiraToast.error('Missing Form!');
      }

      setloading(true);
      await dispatch(
        formActions.deleteForm({ formId: currentForm?._id!, userId: userId }),
      ).unwrap();
      await dispatch(formActions.getMyForms({ userId: userId })).unwrap();
      await dispatch(thresholdActions.getThreshold({ userId })).unwrap();
      setFormDeleteModalVisible(false);
      AtiraToast.success(t('forms.form.delete.success'));
    } catch (error: any) {
      console.log(error);
      AtiraToast.apiError(error);
    } finally {
      setloading(false);
    }
  };

  const cloneForm = async () => {
    try {
      setloading(true);
      await dispatch(
        formActions.cloneForm({ formId: currentForm?._id!, userId }),
      ).unwrap();
      await dispatch(formActions.getMyForms({ userId })).unwrap();
      await dispatch(thresholdActions.getThreshold({ userId })).unwrap();
      setFormDuplicateModalVisible(false);
      AtiraToast.success(t('forms.duplicate.success'));
    } catch (error: any) {
      console.log(error);
      AtiraToast.apiError(error);
    } finally {
      setloading(false);
    }
  };

  useEffect(() => {
    dispatch(formActions.getMyForms({ userId: userId }));
    dispatch(thresholdActions.getThreshold({ userId }));
  }, [dispatch, userId]);

  return (
    <Flex flexDirection="column" justifyContent="space-between" flex={1}>
      <Flex flexDirection="column">
        <SubHeader
          title={t('common.forms')}
          icon={faPlus}
          buttonTitle={t('common.create')}
          onClick={() => navigate('/forms/create')}
          enabled={canCreateForm(state)}
        />

        <Wrapper>
          {userForms.length
            ? userForms.map((f, i) => (
                <UserForm
                  key={f._id}
                  form={f}
                  onEdit={onEdit}
                  onHover={setHoveredFormId}
                  hoveredFormId={hoveredFormId}
                  onDelete={openDeleteFormModal}
                  onShare={openShareModal}
                  onPreview={openPreviewModal}
                  onNavigateToEntries={navigateToFormEntries}
                  onCreateEntry={openCreateNewEntryModal}
                  onDuplicate={openFormDuplicateModal}
                />
              ))
            : null}
        </Wrapper>
      </Flex>

      {!userForms.length ? (
        <Flex alignSelf="center">
          <AtiraEmpty description={t('forms.no_forms_yet')} />
        </Flex>
      ) : null}

      <FormShareModal
        isOpen={formShareModalVisible}
        onClose={() => setFormShareModalVisible(false)}
      />

      <FormReadModal
        isOpen={formReadModalVisible}
        onClose={() => setFormReadModalVisible(false)}
      />

      <WarningModal
        isOpen={formDeleteModalVisible}
        title={t('forms.delete.modal.title')}
        onConfirm={deleteForm}
        loading={loading}
        onClose={() => setFormDeleteModalVisible(false)}
      />

      <EntryCreateModal
        isOpen={entryCreateModalVisible}
        onClose={() => setEntryCreateModalVisible(false)}
        key={currentForm?._id}
      />

      <WarningModal
        title={t(`${t('common.duplicate')}: ${currentForm?.title}`)}
        isOpen={formDuplicateModalVisible}
        onClose={() => setFormDuplicateModalVisible(false)}
        loading={loading}
        onConfirm={cloneForm}
        description={t('forms.duplicate.modal.main_title')}
      />
    </Flex>
  );
};
