import {
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  UniqueIdentifier,
} from '@dnd-kit/core';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '../../components/Button';
import { Flex } from '../../components/Flex';
import { Spinner } from '../../components/Spinner';
import { SpinnerFullScreen } from '../../components/SpinnerFullScreen';
import { SubHeader } from '../../components/SubHeader';
import { Text } from '../../components/Text';
import { WarningModal } from '../../components/WarningModal';
import { DNDContext } from '../../components/dnd/DNDContext';
import { DNDSortableDroppableContext } from '../../components/dnd/DNDSortableDroppableContext';
import { KanbanColumn as KanbanColumnModel } from '../../model/kanban/KanbanColumn';
import { KanbanTask } from '../../model/kanban/KanbanTask';
import { UpdateKanbanCardDto } from '../../model/kanban/dto/UpdateKanbanCardDto';
import { KanbanColumnsCount } from '../../model/kanban/types/KanbanColumnsCount.enum';
import { UserKind } from '../../model/user/types/UserKind.enum';
import { kanbanSliceSelectors } from '../../redux/kanban/kanban.selector';
import { kanbanActions } from '../../redux/kanban/kanban.slice';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { userSliceSelectors } from '../../redux/user/user.selector';
import { Rounded } from '../../theme/Rounded';
import { Spacing } from '../../theme/Spacing';
import { AtiraToast } from '../../utils/AtiraToast';
import {
  getNewColumnsStateForDifferentColumnsDND,
  getNewColumnsStateForSameColumnDND,
} from './KanbanUtils';
import { KanbanCard } from './components/KanbanCard';
import { KanbanCardCreateDrawer } from './components/KanbanCardCreateDrawer';
import { KanbanCardMovingReasonModal } from './components/KanbanCardMovingReasonModal';
import { KanbanCardReadDrawer } from './components/KanbanCardReadDrawer';
import { KanbanCardUpdateDrawer } from './components/KanbanCardUpdateDrawer';
import { KanbanColumn } from './components/KanbanColumn';
import { KanbanHeader } from './components/KanbanHeader';
import { KanbanTaskCreateDrawer } from './components/kanban-task/KanbanTaskCreateDrawer';
import { KanbanTaskUpdateDrawer } from './components/kanban-task/KanbanTaskUpdateDrawer';
import { KanbanTasksReadDrawer } from './components/kanban-task/KanbanTasksReadDrawer';
import { useKanbanContext } from './kanban-context';

const Wrapper = styled(Flex)`
  padding: 0 ${Spacing.m};
  justify-content: flex-start;
  height: 100%;
  overflow-y: hidden;
  flex-direction: column;
`;

const StyledWarningModal = styled(WarningModal)`
  z-index: 1000000;
`;

const AddKanbanWrapper = styled(Flex)<{ canAddColumn: boolean }>`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${Spacing.s} ${Spacing.s} 0 ${Spacing.s};
  background-color: ${(props) => props.theme.lightgray};
  border: 2px solid ${(props) => props.theme.lightgray};
  border-radius: ${Rounded.xl};
  min-width: 18rem;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${({ theme, canAddColumn }) =>
      canAddColumn ? theme.gray : theme.lightgray};
  }
`;

const AddKanbanButton = styled(Button)`
  padding: 0;
  margin: 0;
  width: 10rem;
  font-size: 1rem;
  background-color: ${(props) => props.theme.transparent};
  color: ${(props) => props.theme.darkerSub};
`;

export const Kanban: React.FC = () => {
  /** Item being picked */
  const [activeItemId, setActiveItemId] = useState<
    UniqueIdentifier | undefined
  >();
  const [columns, setColumns] = useState<KanbanColumnModel[]>([]);
  const [
    kanbanCardMovingReasonModalVisible,
    setKanbanCardMovingReasonModalVisible,
  ] = useState(false);
  const [reasonMessage, setReasonMessage] = useState<string | null>(null);
  const [currentEvent, setCurrentEvent] = useState<DragEndEvent | null>(null);
  const [deleteTaskLoading, setDeleteTaskLoading] = useState(false);
  const [addColumnLoading, setAddColumnLoading] = useState(false);
  const [taskDoneLoading, setTaskDoneLoading] = useState(false);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const {
    card: currentKanbanCard,
    setCard,
    task,
    updateDrawerVisible,
    setUpdateDrawerVisible,
    readDrawerVisible,
    setReadDrawerVisible,
    taskCreateDrawerVisible,
    setTaskCreateDrawerVisible,
    tasksReadDrawerVisible,
    setTasksReadDrawerVisible,
    createDrawerVisible,
    setCreateDrawerVisible,
    setTaskDeleteModalVisible,
    taskDeleteModalVisible,
    setTaskUpdateDrawerVisible,
    taskUpdateDrawerVisible,
    taskDoneVisible,
    setTaskDoneVisible,
  } = useKanbanContext();

  const kanban = useAppSelector(kanbanSliceSelectors.selectUserDefaultKanban);
  const kanbanLoading = useAppSelector(
    kanbanSliceSelectors.selectUserDefaultKanbanLoading,
  );
  const user = useAppSelector(userSliceSelectors.selectLoggedInUser)!;

  const canAddColumn =
    user.kind === UserKind.FREE
      ? columns.length < KanbanColumnsCount.FREE
      : columns.length < KanbanColumnsCount.PAID;

  const addColumn = async () => {
    try {
      setAddColumnLoading(true);
      await dispatch(
        kanbanActions.createKanbanColumn({
          userId: user._id,
          kanbanId: kanban?._id!,
        }),
      ).unwrap();
      await dispatch(
        kanbanActions.getUserDefaultKanban({ userId: user._id }),
      ).unwrap();
      AtiraToast.success(t('deals.column.add.success'));
    } catch (e) {
      console.error(e);
      AtiraToast.apiError(e);
    } finally {
      setAddColumnLoading(false);
    }
  };

  const getColumnByCardId = (itemId: UniqueIdentifier) => {
    for (let i = 0; i < columns?.length; i++) {
      const col = columns[i];
      if (col?.cards?.find((card) => card._id === itemId)) {
        return col;
      }
    }
  };

  const getColumnById = (itemId: UniqueIdentifier) => {
    return columns.find(({ _id }) => _id === itemId);
  };

  const onDragStart = (event: DragStartEvent) => {
    const { active } = event;
    setActiveItemId(active.id);
  };

  const onDragConfirm = async (event: DragEndEvent, reasonMessage?: string) => {
    try {
      const { active, over } = event;

      const currentColumn =
        getColumnByCardId(over?.id!) || getColumnById(over?.id!);
      const sourceColumn = getColumnByCardId(active.id);

      if (!currentColumn || !over || active.id === over.id) {
        return;
      }

      let newColumnsState: {
        newOrder?: number;
        oldOrder?: number;
        newColumns: KanbanColumnModel[];
      };
      // If the card is being moved within the same column
      if (!sourceColumn || sourceColumn._id === currentColumn._id) {
        newColumnsState = getNewColumnsStateForSameColumnDND({
          currentColumn,
          columns,
          active,
          over,
        });
        if (newColumnsState.newOrder === newColumnsState.oldOrder) {
          newColumnsState.newOrder = undefined;
          newColumnsState.oldOrder = undefined;
        }
      } else {
        newColumnsState = getNewColumnsStateForDifferentColumnsDND({
          currentColumn,
          sourceColumn,
          columns,
          active,
          over,
        });
      }

      setColumns(newColumnsState.newColumns);

      const dto = omitBy(
        {
          cardId: active.id as string,
          columnId: currentColumn._id,
          userId: user._id,
          newOrder: newColumnsState.newOrder,
          oldOrder: newColumnsState.oldOrder,
          kanbanId: kanban?._id!,
          ...(reasonMessage ? { movingReason: reasonMessage } : {}),
        },
        isNil,
      ) as unknown as UpdateKanbanCardDto;

      await dispatch(kanbanActions.updateKanbanCard(dto)).unwrap();

      if (reasonMessage) {
        AtiraToast.success(t('deals.drawer.moving_reason.success'));
      }
    } catch (e: any) {
      AtiraToast.apiError(e);
      console.log(e);
    } finally {
      setActiveItemId(undefined);
    }
  };

  const onSumbitMessage = (reasonMessage: string) => {
    setReasonMessage(reasonMessage);
    onDragConfirm(currentEvent!, reasonMessage);
  };

  const onDragEnd = (event: DragEndEvent) => {
    const { over, active } = event;

    const currentColumn =
      getColumnByCardId(over?.id!) || getColumnById(over?.id!);
    const sourceColumn = getColumnByCardId(active.id);

    const defaultColumns = columns.filter((column) => column.default);

    const showModal = defaultColumns.find(
      (defaultColumn) =>
        defaultColumn._id === currentColumn?._id &&
        currentColumn._id !== sourceColumn?._id,
    );

    if (showModal) {
      setCurrentEvent(event);
      setKanbanCardMovingReasonModalVisible(true);
    } else {
      onDragConfirm(event);
    }
  };

  const renderDragOverlay = () => {
    if (!activeItemId) {
      return null;
    }

    const activeCard = columns
      .flatMap((col) => col.cards)
      .find((card) => card?._id === activeItemId);

    if (!activeCard) {
      return null;
    }

    return <KanbanCard card={activeCard} />;
  };

  const moveColumn = async (index: number, direction: 'left' | 'right') => {
    try {
      const targetIndex = direction === 'left' ? index - 1 : index + 1;

      const movedcolumnId = columns.find((col) => col.order === index)?._id;

      const canMove =
        targetIndex >= 0 &&
        targetIndex < columns.length &&
        (movedcolumnId?.length || 0) > 0;

      if (canMove) {
        const res = await dispatch(
          kanbanActions.updateKanbanColumnById({
            columnId: movedcolumnId!,
            kanbanId: kanban?._id!,
            userId: user._id,
            oldOrder: index,
            newOrder: targetIndex,
          }),
        ).unwrap();

        setColumns(res.columns!);

        AtiraToast.success(t('deals.column.move.success'));
      }
    } catch (e: any) {
      console.log(e);
      AtiraToast.apiError(e);
    }
  };

  const deleteTask = async () => {
    try {
      setDeleteTaskLoading(true);

      await dispatch(
        kanbanActions.deleteKanbanTask({
          taskId: task?._id!,
          userId: user._id,
        }),
      ).unwrap();

      const updatedKanbanCard = await dispatch(
        kanbanActions.getKanbanCardById({
          cardId: currentKanbanCard?._id!,
          userId: user._id,
        }),
      ).unwrap();

      setCard(updatedKanbanCard);

      setTaskDeleteModalVisible(false);

      AtiraToast.success(t('tasks.delete.success'));
    } catch (e: any) {
      AtiraToast.apiError(e);
      console.log(e);
    } finally {
      setDeleteTaskLoading(false);
    }
  };

  const onTaskDone = async () => {
    try {
      setTaskDoneLoading(true);

      await dispatch(
        kanbanActions.updateKanbanTask({
          userId: user._id,
          taskId: task!._id,
          done: true,
        }),
      ).unwrap();

      const updatedCard = await dispatch(
        kanbanActions.getKanbanCardById({
          cardId: task?.kanbanCardId!,
          userId: user._id,
        }),
      ).unwrap();

      setCard(updatedCard);

      setTaskDoneVisible(false);

      AtiraToast.success(t('tasks.done.success'));
    } catch (e: any) {
      AtiraToast.apiError(e);
      console.log(e);
    } finally {
      setTaskDoneLoading(false);
    }
  };

  const onCloseTaskUpdateDrawer = () => {
    setTaskUpdateDrawerVisible(false);
    setTasksReadDrawerVisible(true);
  };

  useEffect(() => {
    dispatch(kanbanActions.getUserDefaultKanban({ userId: user._id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (kanban) {
      setColumns(kanban?.columns || []);
    }
  }, [kanban, kanban?.columns]);

  if (!columns.length && kanbanLoading) {
    return <SpinnerFullScreen />;
  }

  return (
    <Flex flexDirection="column" overflowY="hidden" flex={1}>
      <SubHeader
        buttonTitle={t('common.add')}
        icon={faPlus}
        onClick={() => setCreateDrawerVisible(true)}
        title={t('common.deals')}
      />

      <Wrapper>
        <KanbanHeader />
        <DNDContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
          <Flex height={'100%'} width={'100%'}>
            {columns.map((col, i) => (
              <DNDSortableDroppableContext
                id={col._id}
                key={col._id}
                items={col.cards || []}
              >
                <KanbanColumn
                  isLast={col.order === columns.length - 1}
                  onMoveRight={() => moveColumn(i, 'right')}
                  onMoveLeft={() => moveColumn(i, 'left')}
                  column={col}
                />
              </DNDSortableDroppableContext>
            ))}

            <AddKanbanWrapper canAddColumn={canAddColumn}>
              {canAddColumn ? (
                <AddKanbanButton
                  iconWidth="2x"
                  icon={faPlus}
                  onClick={addColumn}
                  loading={addColumnLoading}
                >
                  {addColumnLoading ? <Spinner /> : `${t('common.add_column')}`}
                </AddKanbanButton>
              ) : (
                <Text color="darkerSub" fontSize="m">
                  {t('deals.column.limit', {
                    limit:
                      user.kind === UserKind.FREE
                        ? KanbanColumnsCount.FREE
                        : KanbanColumnsCount.PAID,
                  })}
                </Text>
              )}
            </AddKanbanWrapper>

            <DragOverlay
              dropAnimation={{
                duration: 500,
                easing: 'cubic-bezier(0.18, 0.67, 0.6, 1.22)',
              }}
            >
              {renderDragOverlay()}
            </DragOverlay>
          </Flex>
        </DNDContext>

        <KanbanCardCreateDrawer
          isOpen={createDrawerVisible}
          onClose={() => setCreateDrawerVisible(false)}
        />

        <KanbanCardMovingReasonModal
          isOpen={kanbanCardMovingReasonModalVisible}
          onClose={() => setKanbanCardMovingReasonModalVisible(false)}
          onConfirm={onSumbitMessage}
        />

        <KanbanCardUpdateDrawer
          key={currentKanbanCard?._id}
          isOpen={updateDrawerVisible}
          onClose={() => setUpdateDrawerVisible(false)}
          kanbanCard={currentKanbanCard}
        />

        <KanbanCardReadDrawer
          isOpen={readDrawerVisible}
          kanbanCard={currentKanbanCard}
          onClose={() => setReadDrawerVisible(false)}
        />

        <KanbanTaskCreateDrawer
          kanbanCard={currentKanbanCard}
          isOpen={taskCreateDrawerVisible}
          onClose={() => setTaskCreateDrawerVisible(false)}
        />

        <KanbanTasksReadDrawer
          tasks={currentKanbanCard?.kanbanTasks}
          isOpen={tasksReadDrawerVisible}
          onClose={() => setTasksReadDrawerVisible(false)}
        />

        {currentKanbanCard?.kanbanTasks?.length ? (
          <StyledWarningModal
            isOpen={taskDeleteModalVisible}
            loading={deleteTaskLoading}
            onConfirm={deleteTask}
            onClose={() => setTaskDeleteModalVisible(false)}
            title={t('tasks.delete.warning.title', { task_name: task?.name })}
            description={t('tasks.delete.warning.description')}
          />
        ) : null}

        <KanbanTaskUpdateDrawer
          isOpen={taskUpdateDrawerVisible}
          onClose={onCloseTaskUpdateDrawer}
          kanbanTask={task as KanbanTask}
        />

        <WarningModal
          isOpen={taskDoneVisible}
          onClose={() => setTaskDoneVisible(false)}
          title={t('tasks.done.modal.title')}
          description={t('tasks.done.modal.description')}
          onConfirm={onTaskDone}
          loading={taskDoneLoading}
        />
      </Wrapper>
    </Flex>
  );
};
