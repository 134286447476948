import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faX } from '@fortawesome/free-solid-svg-icons/faX';
import { Tooltip } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { Input } from '../../../components/Input';
import { Text } from '../../../components/Text';
import { Modal } from '../../../components/modal/Modal';
import { Rounded } from '../../../theme/Rounded';
import { Spacing } from '../../../theme/Spacing';
import { truncateString } from '../../../utils/String';

const getTruncatedEmail = (email: string) => {
  if (email.length > 40) {
    return (
      <Tooltip title={email}>
        <Text>{truncateString(email, 25)}</Text>
      </Tooltip>
    );
  }

  return <Text>{email}</Text>;
};

const AddButton = styled(Button)`
  margin: 0;
  padding: ${Spacing.s};
  height: 2.3rem;
  margin-top: auto;

  &:hover {
    background-color: ${({ theme }) => theme.lightMain};
  }
`;

const DeleteButton = styled(Button)`
  padding: ${Spacing.s};
  margin: 0;
  background-color: ${({ theme }) => theme.darkSub};
  border: ${({ theme }) => `1px solid ${theme.darkerSub}`};
  color: ${({ theme }) => theme.darkerSub};

  &:hover {
    background-color: ${({ theme }) => theme.darkerSub};
    color: ${({ theme }) => theme.white};
  }
`;

const SubmitButton = styled(Button)`
  width: 7rem;
  font-size: 1rem;
  margin: auto;

  &:hover {
    background-color: ${({ theme }) => theme.lightMain};
  }
`;

const EmailWrapper = styled(Flex)`
  align-items: center;
  background-color: ${({ theme }) => theme.sub};
  border-radius: ${Rounded.md};
  justify-content: space-between;
  padding: 0.3rem;
`;

interface MemberInviteModalProps {
  isOpen: boolean;
  onClose: VoidFunction;
}

export const MemberInviteModal: React.FC<MemberInviteModalProps> = ({
  isOpen = false,
  onClose,
}) => {
  const [email, setEmail] = useState('');
  const [emails, setEmails] = useState<string[]>([]);

  const { t } = useTranslation();

  const onAddEmail = () => {
    setEmails((prev) => [...prev, email]);
    setEmail('');
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title={t('permissions.invites.invite_modal.title')}
    >
      <Text color="darkerSub">
        {t('permissions.invites.invite_modal.description')}
      </Text>

      <Flex marginTop="s" flexDirection="column" gap="m" width={'100%'}>
        <Flex gap="s">
          <Input
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
            placeholder="example@gmail.com"
            title={t('permissions.invites.invite_modal.member_email')}
          />

          <AddButton onClick={onAddEmail} icon={faPlus} iconWidth="lg" />
        </Flex>

        <Flex flexDirection="column" gap="s">
          {emails.map((email, index) => (
            <EmailWrapper>
              <Flex alignItems="center" gap="s">
                <Text>{index + 1} - </Text>

                {getTruncatedEmail(email)}
              </Flex>

              <DeleteButton icon={faX} />
            </EmailWrapper>
          ))}
        </Flex>

        <SubmitButton>{t('common.invite')}</SubmitButton>
      </Flex>
    </Modal>
  );
};
