import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraIcon } from '../../../components/AtiraIcon';
import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import { useAppSelector } from '../../../redux/store';
import { userSliceSelectors } from '../../../redux/user/user.selector';
import { Rounded } from '../../../theme/Rounded';
import { Shadow } from '../../../theme/Shadow';
import { Spacing } from '../../../theme/Spacing';
import { usePermissionsContext } from '../permissions-context';
import { PendingMember } from './PendingMember';

const Container = styled(Flex)`
  background-color: ${({ theme }) => theme.white};
  box-shadow: ${Shadow.SM};
  border-radius: ${Rounded.md};
  padding: ${Spacing.s};
  flex-direction: column;
`;

const HeaderButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${Spacing.s};
  margin: 0;
  padding: ${Spacing.s};
  font-size: 1rem;
`;

const Header = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  border-bottom: ${({ theme }) => `2px solid ${theme.main}`};
  margin-bottom: ${Spacing.xl};
  padding-bottom: ${Spacing.s};
`;

export const PermissionsInvitesTab: React.FC = () => {
  const { t } = useTranslation();

  const { setMembersInviteVisible } = usePermissionsContext();

  return (
    <Container>
      <Header>
        <Text fontWeight={'bold'} fontSize="xm">
          {t('permissions.total.members', { count: [].length })}
        </Text>

        <HeaderButton disabled onClick={() => setMembersInviteVisible(true)}>
          <AtiraIcon icon={faPlus} color="white" />

          {t('permission.add_member')}
        </HeaderButton>
      </Header>

      <Flex flexDirection="column" gap="m">
        {[].map((member) => (
          <PendingMember email={member} memberAccepted={false} />
        ))}
      </Flex>
    </Container>
  );
};
