import React from 'react';

import { Permissions } from './Permissions';
import { PermissionContextProvider } from './permissions-context';

export const PermissionsRoute: React.FC = () => {
  return (
    <PermissionContextProvider>
      <Permissions />
    </PermissionContextProvider>
  );
};
