import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { AtiraTabs } from '../../components/AtiraTabs';
import { Flex } from '../../components/Flex';
import { SubHeader } from '../../components/SubHeader';
import { MemberInviteModal } from './components/MemberInviteModal';
import { PermissionsInvitesTab } from './components/PermissionsInvitesTab';
import { PermissionsMembersTab } from './components/PermissionsMembersTab';
import { usePermissionsContext } from './permissions-context';

export const Permissions: React.FC = () => {
  const { t } = useTranslation();

  const { membersInviteVisible, setMembersInviteVisible } =
    usePermissionsContext();

  const items = [
    {
      key: '1',
      label: t('permissions.members'),
      children: <PermissionsMembersTab />,
    },
    {
      key: '2',
      label: t('permissions.invites'),
      children: <PermissionsInvitesTab />,
    },
  ];

  return (
    <Flex flexDirection="column">
      <SubHeader
        title={t('header.dropdown.permissions')}
        buttonTitle={t('permission.add_member')}
        icon={faPlus}
        onClick={() => setMembersInviteVisible(true)}
        enabled={false}
      />
      <AtiraTabs items={items} />

      <MemberInviteModal
        isOpen={membersInviteVisible}
        onClose={() => setMembersInviteVisible(false)}
      />
    </Flex>
  );
};
