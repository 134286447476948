import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraIcon } from './AtiraIcon';
import { Button } from './Button';
import { Flex } from './Flex';
import { Text } from './Text';
import { Modal } from './modal/Modal';

const StyledWarningModal = styled(Modal)`
  z-index: 1000000;
`;

const DenyButton = styled(Button)`
  background-color: ${({ theme }) => theme.white};
  border: 1px solid ${({ theme }) => theme.black};
  width: fit-content;
  height: 2.5rem;
  color: ${({ theme }) => theme.black};

  &:hover {
    background-color: ${({ theme }) => theme.black};
    color: ${({ theme }) => theme.white};
  }
`;

interface WarningModalProps {
  title?: string;
  description?: string;
  onConfirm?: VoidFunction;
  loading?: boolean;
  isOpen: boolean;
  onClose?: VoidFunction;
}

export const WarningModal: React.FC<WarningModalProps> = ({
  title,
  description,
  onConfirm,
  onClose,
  loading,
  isOpen,
}) => {
  const { t } = useTranslation();

  const onLocalConfirm = () => {
    onConfirm?.();
  };

  return (
    <StyledWarningModal
      open={isOpen}
      onClose={onClose}
      title={t('common.warning')}
    >
      <Flex flexDirection="column" alignItems="center" justifyContent="center">
        <Flex width={'4rem'}>
          <AtiraIcon icon={faTriangleExclamation} size="4x" color="main" />
        </Flex>

        <Flex>
          <Text fontSize="xxl" align="center" color="darkTextColor">
            {title ? title : t('common.are_you_sure')}
          </Text>
        </Flex>

        <Flex>
          <Text fontSize="m" align="center" color="darkTextColor">
            {description ? description : t('forms.delete.modal.description')}
          </Text>
        </Flex>

        <Flex gap="xl">
          <DenyButton onClick={onClose}>{t('common.no')}</DenyButton>

          <Button
            hover
            width="5rem"
            height="2.5rem"
            loading={loading}
            onClick={onLocalConfirm}
          >
            {t('common.yes')}
          </Button>
        </Flex>
      </Flex>
    </StyledWarningModal>
  );
};
