import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle';
import { faX } from '@fortawesome/free-solid-svg-icons/faX';
import { Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraIcon } from '../../../components/AtiraIcon';
import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import { Rounded } from '../../../theme/Rounded';
import { Spacing } from '../../../theme/Spacing';
import { truncateString } from '../../../utils/String';

const getTruncatedEmail = (email?: string) => {
  if (email && email.length > 50) {
    return (
      <Tooltip title={email}>
        <Text fontWeight={'bold'}>{truncateString(email, 50)}</Text>
      </Tooltip>
    );
  }

  return <Text fontWeight={'bold'}>{email}</Text>;
};

const PendingMemberWrapper = styled(Flex)`
  border-radius: ${Rounded.md};
  background-color: ${({ theme }) => theme.sub};
  justify-content: space-between;
  align-items: center;
  padding: ${Spacing.s};
`;

const StyledButton = styled(Button)`
  margin: 0;
  padding: ${Spacing.s};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${Spacing.s};
  color: ${({ theme }) => theme.white};
`;

interface PendingMemberProps {
  email: string;
  memberAccepted: boolean;
}

export const PendingMember: React.FC<PendingMemberProps> = ({
  email,
  memberAccepted,
}) => {
  const { t } = useTranslation();

  return (
    <PendingMemberWrapper>
      {getTruncatedEmail(email || '')}

      {memberAccepted ? (
        <Flex alignItems="center" gap="l">
          <Text color="main">{t('permissions.invite.member_accepted')}</Text>

          <Flex alignItems="center" gap="s">
            <StyledButton backgroundColor="green">
              <AtiraIcon icon={faCheck} />

              {t('common.confirm')}
            </StyledButton>

            <StyledButton backgroundColor="red">
              <AtiraIcon icon={faX} />

              {t('common.remove')}
            </StyledButton>
          </Flex>
        </Flex>
      ) : (
        <Flex alignItems="center" gap="l">
          <Text color="orange">{t('permission.invites.invite.pending')}</Text>

          <AtiraIcon icon={faCircle} color="orange" size="lg" />
        </Flex>
      )}
    </PendingMemberWrapper>
  );
};
