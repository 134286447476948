import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useTheme } from '../../../ThemeContext';
import { Images } from '../../../assets';
import { AtiraCollapse } from '../../../components/AtiraCollapse';
import { Flex } from '../../../components/Flex';
import { WarningModal } from '../../../components/WarningModal';
import { useAppSelector } from '../../../redux/store';
import { userSliceSelectors } from '../../../redux/user/user.selector';
import { Rounded } from '../../../theme/Rounded';
import { Shadow } from '../../../theme/Shadow';
import { Spacing } from '../../../theme/Spacing';
import { usePermissionsContext } from '../permissions-context';
import { MemberPermissions } from './MemberPermissions';
import { MemberRow } from './MemberRow';

const Container = styled(Flex)`
  background-color: ${({ theme }) => theme.white};
  box-shadow: ${Shadow.SM};
  border-radius: ${Rounded.md};
  padding: ${Spacing.s};
  flex-direction: column;
`;

const StyledCollapseWrapper = styled.div`
  .ant-collapse-header {
    padding: 0 !important;
  }

  .ant-collapse-expand-icon {
    padding-inline-end: ${Spacing.s} !important;
    padding-inline-start: 0 !important;
  }
`;

export const PermissionsMembersTab: React.FC = () => {
  const { theme } = useTheme();

  const { t } = useTranslation();

  const { memberRemoveVisible, setMemberRemoveVisible } =
    usePermissionsContext();

  const member = useAppSelector(userSliceSelectors.selectLoggedInUser);

  const panelStyle: React.CSSProperties = {
    borderBottom: '1px solid black',
    backgroundColor: theme.sub,
    border: '0',
  };

  const items = [member].map((member) => ({
    key: `${member?._id}`,
    label: <MemberRow member={member} />,
    children: <MemberPermissions permissions={member?.permissions} />,
    style: panelStyle,
  }));

  return (
    <Container>
      <StyledCollapseWrapper>
        <AtiraCollapse
          collapsible="icon"
          items={items}
          expandIconPosition="end"
        />
      </StyledCollapseWrapper>

      <WarningModal
        isOpen={memberRemoveVisible}
        title={t('premission.member.remove.modal_title')}
        description={t('premission.member.remove.modal_description')}
        onConfirm={() => {}}
        onClose={() => setMemberRemoveVisible(false)}
      />
    </Container>
  );
};
