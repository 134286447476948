import { Switch } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import { Permission } from '../../../model/permissions/Permission';

interface MemberPermissionsProps {
  permissions?: Permission;
}

export const MemberPermissions: React.FC<MemberPermissionsProps> = ({
  permissions,
}) => {
  const { t } = useTranslation();

  const memberPermissions = Object.entries(permissions?.permissions || {});

  return (
    <Flex justifyContent="space-around">
      {memberPermissions.map(([key, value]) => (
        <Flex flexDirection="column" gap="l">
          <Text fontSize="xm" fontWeight={'bold'}>
            {t(`common.${key.toLowerCase()}`)}
          </Text>

          <Flex flexDirection="column" gap="s">
            {Object.entries(value).map(([key, value]) => (
              <Flex alignItems="center" gap="s" justifyContent="space-between">
                <Text>{t(`common.${key.toLowerCase()}`)}</Text>

                <Switch disabled checked={value} />
              </Flex>
            ))}
          </Flex>
        </Flex>
      ))}
    </Flex>
  );
};
