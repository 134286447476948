import { Layout as LayoutComponent } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, ScrollRestoration } from 'react-router-dom';
import styled from 'styled-components';

import { useTheme } from '../ThemeContext';
import { Flex } from '../components/Flex';
import { SideMenu } from '../components/SideMenu';
import { useDocumentTitle } from '../hooks/useDocumentTitle';
import useWindowDimensions from '../hooks/useWindowDimensions';
import i18n, { AppLangs } from '../i18n';
import { appSliceSelectors } from '../redux/app/app.selector';
import { appActions } from '../redux/app/app.slice';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { MobileBlockingModal } from './MobileBlockingModal';
import { Header } from './shared/Header';

const OutletWrapper = styled(Flex)`
  justify-content: space-between;
  width: 100%;
  height: calc(100% - 3.5rem);
  flex-direction: column;
  overflow-y: auto;
  margin-top: 3.5rem;
  background-color: ${({ theme }) => theme.sub};
`;

const StyledSiderLayout = styled(LayoutComponent.Sider)`
  width: 15.5rem;
  overflow: auto;
  height: 100vh;

  .ant-layout-sider-trigger span {
    rotate: ${i18n.language === AppLangs.AR ? '180deg' : '0'};
  }
  .ant-layout-sider-trigger {
    background-color: ${({ theme }) => theme.main} !important;
  }
`;

export const Layout: React.FC = () => {
  const storedValue = useAppSelector(appSliceSelectors.selectSideMenuCollapsed);

  const [collapsed, setCollapsed] = useState(Boolean(storedValue));

  const dispatch = useAppDispatch();
  const { theme } = useTheme();
  const { width } = useWindowDimensions();
  const { t } = useTranslation();
  useDocumentTitle(t('app.name').toUpperCase());

  const onCollapse = (newCollapsed: boolean) => {
    setCollapsed(newCollapsed);
    dispatch(appActions.setSideMenuCollapsed(newCollapsed));
  };

  return width < 768 ? (
    <MobileBlockingModal />
  ) : (
    <LayoutComponent style={{ width: '100%', height: '100%' }}>
      <Header />
      <StyledSiderLayout
        collapsible={window.innerWidth > 992}
        collapsed={collapsed}
        onCollapse={onCollapse}
        style={{ backgroundColor: theme.main }}
      >
        <SideMenu collapsed={collapsed} />
      </StyledSiderLayout>

        <OutletWrapper id='outlet-wrapper'>
          <ScrollRestoration />
          <Outlet />
      </OutletWrapper>
    </LayoutComponent>
  );
};
